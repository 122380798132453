import { WalletMultiButton } from "@solana/wallet-adapter-ant-design";
import { useWallet } from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { useEffect, useRef, useState } from "react";
import { fetchApi } from "../FetchApi";
import bs58 from "bs58";
import { Header } from "antd/lib/layout/layout";
import { Col, message, Row, Select } from "antd";
import axios from "axios";

const { Connection, programs } = require('@metaplex/js');
const { metadata: { Metadata } } = programs;

const { Option } = Select;

export function Game() {

  const { publicKey, signMessage } = useWallet();

  const [sessionId, setSessionId] = useState(null)
  const [mint, setMint] = useState(null)
  const [mints, setMints] = useState([])
  const [accessToken, setAccessToken] = useState(null)
  const [authCode, setAuthCode] = useState(null)
  const selectMintRef = useRef(null);

  window.wallet = useEffect(() => {
    (async () => {
      // await fetchScores()
      if (publicKey) {
        const connection = new Connection(clusterApiUrl(process.env.REACT_APP_SOLANA_NETWORK), "confirmed");
        const metadata = await Metadata.findDataByOwner(connection, publicKey)
        console.log('METADA', metadata)

        const filtered = metadata.filter((m) => m.data.creators.findIndex((c) => c.address === process.env.REACT_APP_CANDY_MACHINE_ID) !== -1)

        Promise.all(filtered.map(async (el) => {
          const uri = await axios.get(el.data.uri)

          return ({ mint: el.mint, name: el.data.name, avatar: uri.data.image })
        }
        )).then(final => setMint(final))

      } else {
        setMints([])
        setMint(null)
        setSessionId(null)
      }
    })();
  }, [publicKey]);

  useEffect(() => {

    window.copyToClipboard = (value) => {
      navigator.clipboard.writeText(value);
    }
    var buildUrl = process.env.PUBLIC_URL + "/Build";
    var loaderUrl = buildUrl + "/ojotris.loader.js";
    var config = {
      dataUrl: buildUrl + "/ojotris.data.unityweb",
      frameworkUrl: buildUrl + "/ojotris.framework.js.unityweb",
      codeUrl: buildUrl + "/ojotris.wasm.unityweb",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "DefaultCompany",
      productName: "Tetris 3 in a row",
      productVersion: "1.0",
    };

    var container = document.querySelector("#unity-container");
    var canvas = document.querySelector("#unity-canvas");
    var loadingBar = document.querySelector("#unity-loading-bar");
    var progressBarFull = document.querySelector("#unity-progress-bar-full");
    var fullscreenButton = document.querySelector("#unity-fullscreen-button");
    var mobileWarning = document.querySelector("#unity-mobile-warning");

    // By default Unity keeps WebGL canvas render target size matched with
    // the DOM size of the canvas element (scaled by window.devicePixelRatio)
    // Set this to false if you want to decouple this synchronization from
    // happening inside the engine, and you would instead like to size up
    // the canvas DOM size and WebGL render target sizes yourself.
    // config.matchWebGLToCanvasSize = false;

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      container.className = "unity-mobile";
      // Avoid draining fillrate performance on mobile devices,
      // and default/override low DPI mode on mobile browsers.
      config.devicePixelRatio = 1;
      mobileWarning.style.display = "block";
      setTimeout(() => {
        mobileWarning.style.display = "none";
      }, 5000);
    } else {
      let width = window.innerWidth;
      let height = window.innerHeight - 46;

      let compWidth = height * 1.77777778;
      let compHeight = width * 0.5625;
      if (compWidth < width) {
        canvas.style.width = compWidth + "px";
        canvas.style.height = height + "px";
      } else {
        canvas.style.width = width + "px";
        canvas.style.height = compHeight + "px";
      }

    }
    loadingBar.style.display = "block";

    let script = document.createElement("script");
    script.src = loaderUrl;
    script.onload = () => {
      window.createUnityInstance(canvas, config, (progress) => {
        progressBarFull.style.width = 100 * progress + "%";
      }).then((unityInstance) => {
        window.unityInstance = unityInstance;
        loadingBar.style.display = "none";
        fullscreenButton.onclick = () => {
          unityInstance.SetFullscreen(1);
        };
      }).catch((message) => {
        alert(message);
      });
    };
    document.body.appendChild(script);

    return () => {
      if (window.unityInstance) {
        window.unityInstance.Quit()
      }
    };

  }, []);

  return (
    <div id="unity-container" className="unity-desktop">
      {/* <WalletMultiButton /> */}
      <canvas id="unity-canvas"></canvas>
      <div id="unity-loading-bar">
        <div id="unity-logo"></div>
        <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full"></div>
        </div>
      </div>
      <div id="unity-mobile-warning">
        WebGL builds are not supported on mobile devices.
      </div>
      <div id="unity-footer">
        <div id="unity-webgl-logo">E = Ojo moving, Spacebar = Furious Ojo</div>
        <div id="unity-fullscreen-button"></div>
        <div id="unity-build-title">Ojotris WL game</div>
      </div>
    </div>
  );
}
