import './App.less';
import React, { useEffect, useMemo, useState } from 'react';
import OjOtris from "./component/OjOtris";
import { clusterApiUrl } from '@solana/web3.js';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-ant-design';
import { PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, TokenPocketWalletAdapter } from '@solana/wallet-adapter-wallets';
import Login from './component/Login';
import { Route, Routes } from 'react-router-dom';
import Footer from './component/Footer';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-ant-design/styles.css');



function App() {
  const network = 'devnet'
  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network: network }),
      new TokenPocketWalletAdapter(),
      // new TorusWalletAdapter(),
      // new LedgerWalletAdapter(),
      // new SolletWalletAdapter({network}),
      // new SolletExtensionWalletAdapter({network}),
    ],
    [network]
  );

  const [accessToken, setAccessToken] = useState(null)
  const [authCode, setAuthCode] = useState(null)

  // GET CODE
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const code = urlParams.get('code');
  //   setAuthCode(code)
  // }, [])

  // console.log('code', authCode)

  // GET ACCESS TOKEN
  // useEffect(() => {
  //   if (authCode) {
  //     (async () => {
  //       await fetch('https://discord.com/api/oauth2/token', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded'
  //         },
  //         body: new URLSearchParams({
  //           client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
  //           client_secret: `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`,
  //           grant_type: `${process.env.REACT_APP_AUTH_GRANT_TYPE}`,
  //           code: authCode,
  //           redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URL}`
  //         })
  //       })
  //         .then((response) => response.json())
  //         .then((data) => {
  //           setAccessToken(data.access_token)
  //           console.log('accesstoken', data)
  //         })
  //         .catch((error) => {
  //           console.log('error_access', error)
  //         });
  //     })()
  //   }
  // }, [authCode])

  // console.log('accessToken', accessToken)

  // ASSIGN TOKEN TO USER DISCORD DATA
  // useEffect(() => {
  //   if (accessToken) {
  //     (async () => {
  //       await fetch('https://discord.com/api/users/@me', {
  //         method: 'GET',
  //         headers: {
  //           'Authorization': `Bearer ${accessToken}`
  //         }
  //       })
  //         .then(response => response.json())
  //         .then(data => {
  //           console.log('user', data)
  //           window.userDiscordData = data
  //         })
  //         .catch(error => {
  //           console.log('error_user', error)
  //         })
  //     })()
  //   }
  // }, [accessToken])

  // console.log('window', window.userDiscordData)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get authorization code from URL
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        console.log('code', code)
        // Exchange code for access token
        const tokenResponse = await fetch('https://discord.com/api/oauth2/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
            client_secret: `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`,
            grant_type: `${process.env.REACT_APP_AUTH_GRANT_TYPE}`,
            code: code,
            redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URL}`
          })
        });
        const tokenData = await tokenResponse.json();
        setAccessToken(tokenData.access_token);
        console.log('accessToken', accessToken)
        // Fetch user data using access token
        const userResponse = await fetch('https://discord.com/api/users/@me', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${tokenData.access_token}`
          }
        });
        const userData = await userResponse.json();
        console.log('userData', userData)
        window.userDiscordData = JSON.stringify(userData);
        console.log('window.userDiscordData', window.userDiscordData)
      } catch (error) {
        console.error(error);
        // Add error handling here
      }
    };

    fetchData();
  }, []);

  return (
    // aux &&
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          {!accessToken ? (
            <>
              <Login />
              <Footer />
            </>
          ) : (
            <OjOtris />
          )
          }
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>

  );
}

export default App;
