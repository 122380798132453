import { Col, Layout, Row, Select, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { Game } from "./Game";
import React, { useEffect, useRef, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { fetchApi } from "../FetchApi";
import bs58 from "bs58";
import { Header } from "antd/lib/layout/layout";
import { WalletMultiButton } from "@solana/wallet-adapter-ant-design";

const { Option } = Select;

const { Connection, programs } = require('@metaplex/js');
const { metadata: { Metadata } } = programs;

function OjOtris() {

  const { publicKey, signMessage } = useWallet();
  const [sessionId, setSessionId] = useState(null)
  const [mint, setMint] = useState(null)
  const [mints, setMints] = useState([])
  const selectMintRef = useRef(null);
  const [scores, setScores] = useState([]);
  const [nftData, setNftData] = useState([])

  console.log('KEY', publicKey)
  console.log('mints', mints)

  // useEffect(() => {
  //   (async () => {
  //     // await fetchScores()
  //     if (publicKey) {
  //       const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
  //       const metadata = await Metadata.findDataByOwner(connection, publicKey)
  //       console.log('METADA', metadata)
  //       setMints(
  //         metadata.filter((m) => m.data.creators.findIndex((c) => c.address === 'G1UAdyZ4YiBWTKcKiJfcHKgevMFvPqUUjfBd6usGSz2D') !== -1)
  //       )
  //     } else {
  //       setMints([])
  //       setMint(null)
  //       setSessionId(null)
  //     }
  //   })();
  // }, [publicKey]);

  // async function fetchUser() {
  //   fetch('https://discord.com/api/users/@me', {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': 'Bearer jdEqQ1qUlSK3YOnqCw2pgZCebZAr3r'
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log('DATA2', data)
  //     })
  //     .catch(error => {
  //       console.log('error2', error)
  //     });
  // }

  // fetchUser()

  async function fetchScores() {
    const scoresData = await fetchApi.get('/scores')
    const scores = scoresData.map((score, idx) => {
      return {
        idx: idx + 1,
        nft: score.nft.name,
        score: score.value
      }
    })
    setScores(scores)
  }

  // async function onMintSelect(value) {
  //   setSessionId(null)
  //   const question = await fetchApi.post('/session/create', { wallet: publicKey, mint: value })
  //   const message = new TextEncoder().encode(question.message);
  //   const signature = await signMessage(message);
  //   await fetchApi.post('/session/authenticate', { sessionId: question.sessionId, signature: bs58.encode(signature) })
  //   setMint(value)
  //   setSessionId(question.sessionId)
  //   selectMintRef.current.blur()
  // }

  async function onGameFinish(points) {
    await fetchApi.post('/score', { sessionId: sessionId, score: points })
    await fetchScores()
  }

  const scoreColumns = [
    {
      title: '',
      dataIndex: 'idx',
      key: 'idx'
    },
    {
      title: 'NFT',
      dataIndex: 'nft',
      key: 'nft',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    }
  ];



  return (
    <div>
      <Layout>
        {/* <Header style={{ background: 'white' }}>
          <Row gutter={10} justify={'end'}>
            <Col>
              {
                mints.length > 0 &&
                <Select placeholder={'Select a mint'} size={'large'} value={mint} onChange={onMintSelect}
                  ref={selectMintRef}>
                  {mints.map(mint => {
                    return <Option value={mint.mint}>{mint.data.name}</Option>
                  })
                  }
                </Select>
              }
            </Col>

          </Row>
        </Header> */}
        <Content style={{ background: 'white' }}>
          <Row justify={'center'}>
            <Col>
              <Game publicKey={publicKey} onGameFinish={onGameFinish} sessionId={sessionId} />
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>);
}

export default OjOtris;
