import { Button } from 'antd'
import React from 'react'

const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID
const RESPONSE_TYPE = process.env.REACT_APP_AUTH_RESPONSE_TYPE
const SCOPE = process.env.REACT_APP_AUTH_SCOPE
const REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT_URL

const Login = () => {

  const url = `https://discord.com/oauth2/authorize?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&scope=${SCOPE}&redirect_uri=${REDIRECT_URL}`

  return (
    <div id='login'>
      <picture>
        <source srcSet="/TemplateData/ojotris_avatar.webp" type="image/webp" />
        <img src="/TemplateData/ojotris_avatar.png" alt='Ojotris avatar' loading='lazy' width={200} height={200} />
      </picture>
      <h2>Ojotris. The Game</h2>
      <h1>Let's Play!</h1>
      <a href={url}>
        <button><img src="/TemplateData/discord_logo.svg" alt="Discord logo" /> Sign In with Discord</button>
      </a>
    </div>
  )
}

export default Login
