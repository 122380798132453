import React from 'react'

const Footer = () => {

  return (
    <footer>
      <img id='tetris' alt='tetris' src='/TemplateData/ojo_tetris.svg' />
    </footer>
  )
}

export default Footer
